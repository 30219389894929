import React from "react";
import "./overview.styles.css";

const Overview = () => {
  return (
    <div className="overview-div">
      <div className="hours-div">
        <h1>HOURS</h1>
        <h2>Total: 5520</h2>
        <h2>PIC Jet: 1506</h2>
        <h2>SIC: 2863</h2>
        <h2>Night: 421</h2>
        <h2>Tailwheel: 135</h2>
        <h2>PIC Piston: 835</h2>
        <h2>TurboProp: 787</h2>
        <h2>Turbine 3578</h2>
      </div>
      <div className="flightmap-div"></div>
    </div>
  );
};
export default Overview;
