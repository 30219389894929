import { useNavigate } from "react-router-dom";
import React, { useCallback } from "react";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import "../main/main.styles.css";

const MainPage = () => {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = "/overview";
    navigate(path);
  };
  const handleCaptureClick = useCallback(async () => {
    const canvas = await html2canvas(document.body);
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, "donaldposkitt.com.png", "image/png");
  }, []);

  return (
    <div className="main-div">
      <div className="content-div" onClick={handleCaptureClick}>
        <h1 className="name-div">Donald J Poskitt</h1>
        <h2 className="location-div">West Palm Beach, FL</h2>
        <h3 className="number-div">561-281-1679</h3>
        <h4 className="email-div">donald.poskitt@yahoo.com</h4>
        <h5 className="extra-div">Pilot, Programmer, Mentor</h5>
      </div>
      <button className="button-div" onClick={routeChange}>
        Pilot Flight Times and Map
      </button>
    </div>
  );
};

export default MainPage;
