import React from "react";
import "./App.css";
import Overview from "./overview/overview.component";
import MainPage from "./main/main.component";
import { Route, Routes } from "react-router";

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/overview" element={<Overview />} />
        <Route path="/" element={<MainPage />} />
      </Routes>
    </div>
  );
};

export default App;
